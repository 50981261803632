html {
  /*overflow:hidden;*/
  font-family: monospace;
  font-size: 16px;
}

@media screen and (min-width: 320px) {
  html {
    font-size: calc(16px + 3 * ((100vw - 320px) / 680));
  }
}
@media screen and (min-width: 1000px) {
  html {
    font-size: 22px;
  }
}

body {
  font-family: monospace;
}

h2, .h2 {
  font-size:1.25rem;
}

.App {
  text-align: center;
}

footer {
  color:white;
  font-size:large;
}

.App-logo {
  height: 8vmin;
  pointer-events: none;
  float:left;
}

.blogrow {
  background-color: rgba(255,255,255,.75) ;
}

.midnight-text {
  color:midnightblue;
  font-family: monospace;
}

.App-header {
  background-color: black;
  color:white;
  min-height: 8vmin;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: monospace;
}

.App-link {
  color: #61dafb;
}

.resultlist {
  height:  80vh;
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
}