
*,
*:before,
*:after {
  box-sizing: border-box;
}

/*********** full background *********************************/
body {
  background: url("/public/images/bkground.jpg") no-repeat fixed center center/cover #fff;

  input.form-control, select.form-control {
    background-color: rgba(0,0,0,.5);
    color: #fff;
  }
}

/*********** micro formats **********************/
.street-address, .locality, .region, .postal-code, .tel, .distance {
  font-size: 1.5rem;
}

ol.gradient-list  li  h3 {
  color:#fff;
  background-color: rgb(97, 194, 194);
}

ol.gradient-list {
  counter-reset: gradient-counter;
  list-style: none;
  margin: 1.75rem 0;
  padding-left: 1rem;
  > li {
    background: white;
    border-radius: 0 0.5rem 0.5rem 0.5rem;
    box-shadow: 0.25rem 0.25rem 0.6rem rgba(0,0,0,0.05), 0 0.5rem 1.125rem rgba(75,0,0,0.05);
    counter-increment: gradient-counter;
    margin-top: 1rem;
    min-height: 3rem;
    padding: 1rem 1rem 1rem 3rem;
    position: relative;
    &::before,
    &::after {
      background: linear-gradient(135deg, #83e4e2 0%,#a2ed56 100%);
      border-radius: 1rem 1rem 0 1rem;
      content: '';
      height: 4rem;
      left: -1rem;
      overflow: hidden;
      position: absolute;
      top: -1rem;
      width: 4rem;
    }
    &::before {
      align-items: flex-end;
      box-shadow: 0.25rem 0.25rem 0.6rem rgba(0,0,0,0.05), 0 0.5rem 1.125rem rgba(75,0,0,0.05);
      content: counter(gradient-counter);
      color: darkolivegreen;
      display: flex;
      font: 900 2.25em/1 'Calibri';
      justify-content: flex-end;
      padding: 0.125em 0.25em;
      z-index: 1;
    }
    
  }
}
